import React from "react";
import * as styles from "./TermsAndConditions.module.scss";

const TermsAndConditionsPage = ({}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.heroSection}>
        <div className={styles.textWrapper}>
          <div className={styles.name}>Terms and Conditions</div>
          <h1 className={styles.title}>Terms and Conditions</h1>
        </div>
      </div>
      <div className={styles.termsSection}>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 1 - License grant & restrictions
          </div>
          <p className={styles.description}>
            During the Term of this Agreement, Mechanic Advisor grants the
            non-exclusive, non-transferable, right to use the Service, solely
            for the Customer’s own internal business purposes, subject to the
            terms and conditions of this Agreement. All rights not expressly
            granted to the Customer are reserved by Mechanic Advisor and its
            third party licensors or suppliers.
          </p>
          <p className={styles.description}>
            The Customer shall not distribute or otherwise commercially exploit
            or make available to any third party the Service or the content
            provided by or on behalf of Mechanic Advisor or reverse engineer or
            access the Service in order to (i) build a competitive product or
            service, (ii) build a product using similar ideas, features,
            functions or graphics of the Service, or (iii) copy ideas or user
            interface features of the Service.
          </p>
          <p className={styles.description}>
            The Customer agrees that Mechanic Advisor may publish, modify and
            amend any and all Content appearing within <b>Steercrm.com</b>, and
            all other internet domains or content feeds owned, managed, or
            controlled by Mechanic Advisor, including Content consisting of
            promotions, advertisements and listings for non-competing local
            businesses, or products and services offered by Mechanic Advisor.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 2 - Intellectual property rights and customer data
          </div>
          <p className={styles.description}>
            The Customer retains all right, title and interest to any and all
            customer data captured by the Mechanic Advisor system («Customer
            Data») or provided to Mechanic Advisor, subject to Mechanic
            Advisor’s right to use such Customer Data to provide the Service to
            the Customer. This Agreement is not a sale and does not convey to
            the Customer any rights of ownership in or related to the Mechanic
            Advisor Service, Mechanic Advisor technology, Mechanic Advisor
            Content, or Mechanic Advisor intellectual property except for the
            limited licenses granted to the Customer under this Agreement. Any
            and all software, algorithms, applications, source codes,
            structures, sequences, routines, sub-routines and related
            programming, engineering or technological matter developed or
            created by Mechanic Advisor or its licensors (and all copyrights,
            patents, trademarks and other proprietary rights related thereto)
            shall remain the sole, exclusive and perpetual property of Mechanic
            Advisor or its licensors. Mechanic Advisor works with third parties
            to offer its customers additional products and services from time to
            time. Mechanic Advisor collects anonymized, aggregate data in order
            to provide such additional product and services to the Customer and
            the Customer agrees to permit us to use such anonymized, aggregate
            data.
          </p>
          <p className={styles.description}>
            The trademarks, trade names, service names or logos associated with
            the Service (collectively, the «Marks») are trademarks of Mechanic
            Advisor or its licensors, and no right or license is granted to use
            them. The Customer hereby acknowledges Mechanic Advisor or its
            licensors’ perpetual and exclusive ownership of and title to the
            Marks and the goodwill attaching thereto. The Customer agrees not to
            use or attempt to register any Mark that is confusingly or
            deceptively similar to the Marks.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 3 - Customer data and account information
          </div>
          <p className={styles.description}>
            Mechanic Advisor does not own any Customer Data, information or
            material that the Customer submits to the Service in the course of
            using the Service. Except in accordance with this Agreement, if
            applicable, or as required by law, Customer Data in identifiable
            form will not be disclosed, sold, assigned, licensed or otherwise
            disposed of by Mechanic Advisor to any third party. The Customer,
            not Mechanic Advisor, shall have sole responsibility for the
            accuracy, quality, integrity, legality, reliability,
            appropriateness, and intellectual property ownership or right to use
            of all Customer Data, and, except as provided in this Agreement or
            as required by law, Mechanic Advisor shall not be responsible or
            liable for the deletion, correction, destruction, damage, loss or
            failure to store any Customer Data, or for the improper or erroneous
            upload or extraction of any Customer Data. Mechanic Advisor reserves
            the right to withhold, remove and/or discard Customer Data without
            notice for any breach, including, without limitation, the Customer’s
            non-payment as specified in this Agreement. Upon termination for
            cause, the Customer’s right to access or use Customer Data
            immediately ceases and Mechanic Advisor shall have no obligation to
            maintain or forward any Customer Data. Notwithstanding the
            foregoing, if the Customer is part of a franchise organization which
            has or later executes an agreement with Mechanic Advisor, and the
            franchisee has granted the franchisor access to their data,
            Customer’s Data may be shared with the franchisor, and/or franchise
            association.
          </p>
          <p className={styles.description}>
            Mechanic Advisor may, but has no obligation to, monitor any content
            created by the Customer using the Service. Mechanic Advisor may
            disclose any information necessary or appropriate to satisfy
            Mechanic Advisor’s legal obligations, protect Mechanic Advisor or
            its customers, or operate the Service properly. Mechanic Advisor, in
            its sole discretion, may refuse to post, remove, or require Customer
            to remove, any of the Customer’s content, in whole or in part,
            alleged to be unacceptable, undesirable, inappropriate, or in
            violation of this Agreement. Mechanic Advisor may, in its
            discretion, also require the Customer to place all or any portion of
            the Customer’s content behind password protection. If Mechanic
            Advisor has requested Customer content to be or have been placed
            behind password protection, Customer may not publish the password or
            similar information in any way that limits the effectiveness of the
            password. If Mechanic Advisor requests that the Customer place any
            of the Customer’s content behind password protection and the
            Customer fails to do so promptly, Mechanic Advisor may (a) place
            such content behind password protection itself, or (b) immediately
            terminate this Agreement.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 4 - Customer responsibility and passwords
          </div>
          <p className={styles.description}>
            Customer is solely responsible for any and all activity occurring
            under the Customer’s user accounts and shall abide by all applicable
            local, state, national and foreign laws, treaties and regulations in
            connection with the Customer’s use of the Service, including those
            related to data privacy, international communications and the
            transmission of technical or personal data. The Customer also will
            choose a password and a user name. The Customer is entirely
            responsible for maintaining the confidentiality of their password
            and account. The customer agrees to notify Mechanic Advisor
            immediately of any unauthorized use of their account or any breach
            of security. Mechanic Advisor will not be liable for any loss that
            the Customer may incur as a result of someone else using Customer’s
            password or account, either with or without Customer’s knowledge.
            The Customer warrants and represents that: (i) the content to be
            transmitted by or on the Customer’s behalf does not constitute SPAM;
            (ii) the content to be transmitted by or on the Customer’s behalf is
            not illegal, threatening, hateful, vulgar, obscene, libelous or
            defamatory and does not and will not infringe upon any trademark,
            patent, copyright, trade secret or other proprietary, publicity or
            privacy right of any third party; and (iii) the Customer has
            complied and will comply with all applicable laws and regulations
            respecting the Customer’s execution and performance of this
            Agreement.
          </p>
          <p className={styles.description}>
            The Mechanic Advisor Service receives data from third-party software
            systems, which Customer will designate in the process of setting up
            the Mechanic Advisor Service. If the Customer elects to change,
            upgrade or materially alter the third party software system from
            which Mechanic Advisor receives data, Mechanic Advisor does not
            guarantee that all Customer Data or Service functionality will be
            preserved. The Customer is responsible for communicating any changes
            in data structure, management system, or hardware upgrades that may
            impact Mechanic Advisor’s ability to receive and process Customer
            Data. In addition, Customer is responsible for providing Mechanic
            Advisor with accurate instructions and information regarding the
            third party systems and databases with which the Service will
            interface, and the Customer bears all responsibility for incomplete,
            inaccurate or otherwise faulty information regarding third party
            systems and databases belonging to Customer as conveyed to Mechanic
            Advisor in connection with set up or maintenance of the Service.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>Section 5 - Limited liability</div>
          <p className={styles.description}>
            IN NO EVENT SHALL MECHANIC ADVISOR, ITS OFFICERS, DIRECTORS,
            EMPLOYEES, LICENSORS, PARTNERS OR AFFILIATES BE LIABLE FOR, EVEN IF
            MECHANIC ADVISOR HAS BEEN ADVISED OR WARNED OF THE POSSIBILITY OF
            SUCH DAMAGES: (I) ANY INDIRECT, INCIDENTAL, UNFORESEEABLE, SPECIAL,
            PUNITIVE OR CONSEQUENTIAL DAMAGES; (II) ANY DAMAGES FOR LOSS OF
            PROFITS, LOSS OF EARNINGS OR LOSS OF BUSINESS OPPORTUNITIES; (III)
            COSTS OF PROCUREMENT OR SUBSTITUTE GOODS OR SERVICES; (IV) LOSS OF
            DATA OR OTHER OF THE CUSTOMER’S CONTENT RESULTING FROM DELAYS,
            NON-DELIVERIES, MISDELIVERIES, SECURITY BREACHES TO, SERVICE
            INTERRUPTIONS TO, OR ERRORS OR OMISSIONS RESPECTING THE SERVICE OR
            THE OPERATION OF MECHANIC ADVISOR OR ITS LICENSORS’ NETWORKS; OR (V)
            LOSSES OR LIABILITIES DUE IN WHOLE OR IN PART TO INADVERTENT,
            PREMATURE OR UNAUTHORIZED RELEASE OR DISCLOSURE OF INFORMATION BY
            THE CUSTOMER VIA MECHANIC ADVISOR OR ITS LICENSORS’ NETWORKS. THE
            TOTAL CUMULATIVE LIABILITY OF MECHANIC ADVISOR TOGETHER WITH ITS
            PARENT, SUBSIDIARIES, OFFICERS, DIRECTORS, EMPLOYEES, LICENSORS,
            PARTNERS AND AFFILIATES TO THE CUSTOMER OR ANY THIRD PARTIES IN ANY
            CIRCUMSTANCE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE
            SERVICE IS LIMITED TO THE AMOUNT OF FEES THE CUSTOMER PAYS TO
            MECHANIC ADVISOR IN THE TWELVE (12) MONTHS PRIOR TO THE ACTION
            GIVING RISE TO LIABILITY. The foregoing limitations will apply even
            if Mechanic Advisor has been notified of the possibility of such
            damages and notwithstanding the failure of the essential purpose of
            any limited remedy. No action or claim relating to this Agreement
            shall be made against Mechanic Advisor or its parent, Licensors,
            subsidiaries, officers, directors, employees, partners or affiliates
            by the Customer or on the Customer’s behalf more than TWELVE (12)
            months after the event giving rise to such action or claim.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>Section 6 - Indemnity</div>
          <p className={styles.description}>
            The Customer agrees to indemnify and hold Mechanic Advisor
            (including its officers, directors, agents, and employees,
            contractors, sub-contractors, licensors, and partners) harmless from
            any claim, demand, judgment, liability, damage, cost and expense,
            including reasonable attorneys’ fees, made by any third party due to
            or arising out of the Customer’s breach or alleged breach of this
            Agreement or the documents it incorporates by reference, or the
            Customer’s violation of any applicable law, rule or regulation or
            the rights of a third party (including without limitation any
            negligent, willful, tortious or illegal conduct by Customer
            affecting a third party).
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>Section 7 - Term</div>
          <p className={styles.description}>
            Unless otherwise agreed by Mechanic Advisor, this Agreement will{" "}
            <b>AUTOMATICALLY RENEW</b> on a month- to-month basis.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>Section 8 - Termination</div>
          <p className={styles.description}>
            Any breach of the Customer’s payment obligations or unauthorized use
            of the Mechanic Advisor Technology, Content or Service, or Breach of
            the Privacy Policy (as defined on Steercrm.com) will be deemed a
            material breach of this Agreement. Mechanic Advisor, in its sole
            discretion, may terminate the Customer’s password, account or use of
            the Service if Customer breach or otherwise fail to comply with this
            Agreement. In addition to any other rights granted to Mechanic
            Advisor herein, Mechanic Advisor reserves the right to suspend or
            terminate this Agreement and the Customer’s access to the Service if
            the Customer’s account becomes delinquent, if the Customer violates
            the Mechanic Advisor Privacy Policy, the Mechanic Advisor Terms of
            Use, or otherwise engage in fraudulent or unlawful activities. In
            addition, Mechanic Advisor may terminate a free account at any time
            in its sole discretion. The Customer agrees and acknowledges that
            Mechanic Advisor has no obligation to retain the Customer Data, and
            may delete such Customer Data, if the Customer has materially
            breached this Agreement, and such breach has not been cured within
            thirty (30) days of notice of such breach. In addition, failure to
            pay Service Fees will result in termination of Services as deemed
            appropriate by Mechanic Advisor.
          </p>
          <p className={styles.description}>
            To cancel the Service, a Customer must provide Mechanic Advisor with
            written notice at least thirty (30) days in advance. The notice must
            be sent to Mechanic Advisors’ customer success team at{" "}
            <b>
              <a href="mailto:CSSupport@steercrm.com">CSSupport@steercrm.com</a>
            </b>
            . The Customer’s Service will terminate 30 days after the next
            recurring billing cycle subsequent to receiving the cancellation
            notice. If the Customer fails to provide the required notice, the
            Service will automatically renew for the subsequent billing cycle,
            and no refunds will be provided for any prepaid amounts.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>Section 9 – Billing and pricing</div>
          <p className={styles.description}>
            Mechanic Advisor charges and collects in advance for use of the
            Service. No refunds will be available for fees the Customer has
            paid. Mechanic Advisor will automatically renew and bill the
            Customer's credit card every month for monthly licenses and fees, or
            in an otherwise mutually agreed upon manner. The Customer expressly
            agrees that Mechanic Advisor is authorized to charge the Customer
            (i) a recurring monthly fee for any applicable Service billed on a
            monthly basis automatically, (ii) any other fees for the Service the
            Customer may purchase, and (iii) any applicable taxes in connection
            with the Customer's use of the Service to the credit card that the
            Customer provides and to reimburse Mechanic Advisor for all
            collection costs and any overdue amounts.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>9.1 Email finder product</div>
          <p className={styles.description}>
            Mechanic Advisor works with a third-party provider to gather email
            addresses for individuals in the Customer’s database for which the
            Customer does not currently have email addresses on file. By opting
            in to use Mechanic Advisor’s email finder product,the Customer will
            be subject to the monthly service fee once email finder begins for
            that calendar month; email finder begins once Mechanic Advisor has
            passed the Customer’s data to the Mechanic Advisor third-party
            provider. The fees are based on per-valid email returned each month
            from the provider.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>9.2 Mailer product</div>
          <p className={styles.description}>
            By opting in to use Mechanic Advisor’s mailer product, the Customer
            will be subject to the service fees charged per printed postcard.
            The Customer assumes full responsibility that any custom content
            they submit is correct, and in the event that the Customer submits
            incorrect information, misspellings, grammatical errors, etc., the
            Customer agrees to pay any and all associated correction fees.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>9.3 Taxes</div>
          <p className={styles.description}>
            Unless otherwise stated, the Mechanic Advisor fees do not include
            any taxes, levies, duties or similar governmental assessments of any
            nature, including but not limited to value-added, sales, use or
            withholding taxes, assessable by any local, state, provincial,
            federal or foreign jurisdiction, plus interest, penalty and
            additions thereon (collectively, «Taxes»). The Customer is
            responsible for paying all Taxes applicable to their purchase or use
            of Mechanic Advisor products or services. If Mechanic Advisor has
            the legal obligation to pay or collect Taxes (or an amount in
            respect of Taxes) for which the Customer is responsible under this
            paragraph, the appropriate amount shall be invoiced to and paid by
            the Customer, unless the Customer provides Mechanic Advisor with a
            valid tax exemption certificate authorized by the appropriate taxing
            authority. Mechanic Advisor is responsible solely for taxes based on
            its income.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>9.4 Reviews</div>
          <p className={styles.description}>
            Mechanic Advisor works with a third party-provider to request
            reviews on behalf of the Customer. By opting into this service,
            Customer agrees to adhere to the Terms of Service of all major
            review sites. This includes but is not limited to Google, Facebook,
            Yelp, and other review sites. In addition, Customer agrees to adhere
            to industry best practices and FTC regulations, including but not
            limited to the following:
          </p>
          <p className={styles.description}>
            <ul>
              <li>
                Fake or False Consumer Reviews, Consumer Testimonials, and
                Celebrity Testimonials.
              </li>
              <li>Buying Positive or Negative Reviews.</li>
              <li>Insider Reviews and Consumer Testimonials.</li>
              <li>Company-Controlled Review Websites.</li>
              <li>Review Suppression.</li>
              <li>Misuse of Fake Social Media Indicators.</li>
              <li>
                Review Gating which is a process where companies send a message
                to customers asking if their experience was positive or
                negative. Those who had a positive experience are directed to
                leave an online review. Those who had a negative experience are
                typically sent to a form to leave comments that are not made
                public.
              </li>
            </ul>
          </p>
          <p className={styles.description}>
            The Mechanic Advisor Review default set-up meets FTC requirements.
            However, Mechanic Advisor can be personalized by the Customer and
            the Customer’s staff, so we encourage the Customer to educate
            themself on FTC regulations by visiting the{" "}
            <b>
              <a href="https://www.ftc.gov/system/files/ftc_gov/pdf/r311003consumerreviewstestimonialsfinalrulefrn.pdf">
                FTC Website
              </a>
            </b>
            .
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 10 - Credit card authorization
          </div>
          <p className={styles.description}>
            By submitting the Customer’s credit/debit card («Bank Card») data to
            Mechanic Advisor, the Customer authorizes Mechanic Advisor in its
            complete discretion to submit a financial transaction(s) to
            Customer’s issuing bank for settlement. The Customer agrees that
            once Mechanic Advisor has approved or declined the Customer’s
            transaction, Mechanic Advisor has fully performed under the terms of
            this Agreement.{" "}
            <b>
              The Customer agrees to contact Mechanic Advisor in the event that
              they desire to cancel any recurring charge, prior to the next
              billing cycle.
            </b>{" "}
            Should the Customer fail to contact Mechanic Advisor, they agree to
            indemnify and hold Mechanic Advisor harmless from any losses or
            damages that they suffer as a result of a recurring charge. Mechanic
            Advisor may be contacted at:{" "}
            <b>
              <a href="mailto:support@mechanicadvisor.com">
                support@MechanicAdvisor.com
              </a>
            </b>{" "}
            or Mechanic Advisor, Inc., 11 Elkins Street. Suite 310, Boston, MA
            02127, (617)765-8187. If the Customer thinks that there is an error
            on their account, including an incorrect amount or unauthorized
            transaction, the Customer agrees to contact Mechanic Advisor prior
            to the next billing cycle. Upon proper notification, Mechanic
            Advisor, in its sole discretion may issue a credit to the Customer’s
            Account.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 11 - Representations & warranties
          </div>
          <p className={styles.description}>
            The Customer represents and warrants that they have the power and
            authority to enter into this Agreement and the Customer has not
            falsely identified themself or the Customer’s corporate entity, or
            provided any false information to gain access to the Service, and
            that all Bank Card and other billing information that the Customer
            has provided is correct. THE SERVICE IS PROVIDED «AS IS» AND «AS
            AVAILABLE». EXCEPT AS EXPLICITLY SET FORTH ABOVE, MECHANIC ADVISOR
            IS NOT PROVIDING ANY WARRANTIES AND REPRESENTATIONS REGARDING THE
            SERVICE, CONTENT OR TECHNOLOGY, AND MECHANIC ADVISOR AND ITS
            LICENSORS, DISTRIBUTORS, PARTNERS AND AFFILIATES (COLLECTIVELY, THE
            “AFFILIATES”) DISCLAIM ALL WARRANTIES AND REPRESENTATIONS OF ANY
            KIND WITH REGARD TO THE SERVICE, CONTENT AND TECHNOLOGY, INCLUDING
            ANY IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD
            PARTY RIGHTS, FREEDOM FROM VIRUSES OR OTHER HARMFUL CODE, OR FITNESS
            FOR ANY PARTICULAR PURPOSE. FURTHER, MECHANIC ADVISOR, ITS PARENT,
            ANY THIRD PARTIES ACTING ON ITS BEHALF, AND ITS AFFILIATES WILL NOT
            BE LIABLE FOR ANY DELAY, DIFFICULTY IN USE, INACCURACY OF
            INFORMATION, COMPUTER VIRUSES, MALICIOUS CODE OR OTHER DEFECT IN THE
            SERVICE, OR FOR ANY OTHER PROBLEMS EXPERIENCED BY THE CUSTOMER DUE
            TO CAUSES BEYOND MECHANIC ADVISOR’S OR ANY THIRD PARTIES ACTING ON
            ITS BEHALF, OR ITS AFFILIATES’ CONTROL.
          </p>
          <p className={styles.description}>
            FURTHER, MECHANIC ADVISOR EXPRESSLY DISCLAIMS ANY AND ALL
            RESPONSIBILITY AND LIABILITY WITH RESPECT TO SEPARATE AGREEMENTS THE
            CUSTOMER MAY MAKE WITH CONSUMERS OR SITE USERS, AND THE CUSTOMER
            WILL LOOK SOLELY TO SUCH PERSONS AND/OR ENTITIES WITH RESPECT TO ANY
            AND ALL CLAIMS ARISING OUT OF SUCH AGREEMENTS. THE CUSTOMER WILL BE
            SOLELY RESPONSIBLE FOR THE PROFESSIONAL AND TECHNICAL SERVICES THEY
            PROVIDE. MECHANIC ADVISOR HAS NO LIABILITY FOR THE CONSEQUENCES TO
            CONSUMERS OR SITE USERS FOR USE OF THE SERVICE.
          </p>
          <p className={styles.description}>
            MECHANIC ADVISOR OFFERS NO ASSURANCE THAT THE CUSTOMER’S USE OF THE
            SERVICE UNDER THE TERMS OF THIS AGREEMENT WILL NOT VIOLATE ANY LAW
            OR REGULATION APPLICABLE TO THE CUSTOMER. MECHANIC ADVISOR AND ITS
            LICENSORS MAKE NO REPRESENTATION OR WARRANTIES THAT THE SERVICE OR
            THE CONTENT ARE APPROPRIATE OR AVAILABLE FOR USE IN ALL GEOGRAPHIC
            LOCATIONS. IF THE CUSTOMER USES THE SERVICE OR THE CONTENT OUTSIDE
            THE UNITED STATES OF AMERICA, THE CUSTOMER IS SOLELY RESPONSIBLE FOR
            COMPLIANCE WITH ALL APPLICABLE LAWS, INCLUDING WITHOUT LIMITATION
            EXPORT AND IMPORT REGULATIONS OF OTHER COUNTRIES.
          </p>
          <p className={styles.description}>
            <b>
              IN ADDITION, THE CUSTOMER ACKNOWLEDGES AND AGREES THAT ANY DATA,
              INFORMATION, CONTENT OR MATERIALS CONTAINED IN OR MADE AVAILABLE
              IN CONNECTION WITH THE SERVICE IS NOT INTENDED AS A SUBSTITUTE
              FOR, THE KNOWLEDGE, EXPERTISE, SKILL AND JUDGMENT, TAX, LEGAL OR
              OTHER PROFESSIONALS. THE SERVICE DOES NOT PROVIDE TAX OR LEGAL
              ADVICE. THE CUSTOMER IS RESPONSIBLE FOR OBTAINING SUCH ADVICE.
            </b>
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 12 - Exclusions not applicable
          </div>
          <p className={styles.description}>
            <b>
              Certain states and/or jurisdictions do not allow the exclusion of
              implied warranties or limitation of liability for incidental or
              consequential damages, so the exclusions set forth in this
              Agreement may not apply to the Customer. In such event, Mechanic
              Advisor’s liability under this Agreement will be limited to the
              greatest extent permitted by applicable law in such jurisdiction.
            </b>
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>Section 13 - Email compliance</div>
          <p className={styles.description}>
            The Customer agree to comply with all elements of CAN-SPAM and safe
            sender email practices. This includes but is not limited to
            including unsubscribe links, Customer’s full contact information in
            all correspondence, and not releasing private and/or confidential
            information. The Customer may only use email services for those
            customers with whom they have an existing business relationship and
            which have indicated that they accept correspondence from the
            Customer. The Customer may not attempt to spoof sender domains, send
            spam or other offending email practices including those covered in
            the Section titled “ Customer responsibility and passwords;
            third-party software” of this Agreement. Because of carrier
            technologies, Mechanic Advisor makes no expressed or implied
            warranty of individual message receipt. Mechanic Advisor is not
            liable for any issues that arise associated with the content that
            the Customer provides or unforeseen liabilities of it being
            delivered.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 14 - Text message compliance
          </div>
          <p className={styles.description}>
            The Telephone Consumer Protection Act (TCPA) is a federal law
            regulating the way consumers are contacted by telephone, fax, and
            text message. The TCPA regulations apply to the text and automated
            landline messages the Customer is able to send through the Mechanic
            Advisor Service to communicate with the Customer’spatrons.
          </p>
          <p className={styles.description}>
            <b>Prior Consent</b>
          </p>
          <p className={styles.description}>
            TCPA regulations require that companies obtain consent from patrons
            prior to sending any sort of text or automated telephone messages.
            The TCPA regulations are described below to help the Customer
            understand the restrictions . Mechanic Advisor automates text
            message communications, but the Customer is responsible for ensuring
            that the recipients of those communications have provided prior
            express written consent to receive them.
          </p>
          <p className={styles.description}>
            The Mechanic Advisor Service includes default templates that meet
            the requirements for information-only content. However, Mechanic
            Advisor Service messages can be personalized by the Customer and the
            Customer’s staff, so we encourage the Customer to educate themself
            on TCPA guidelines by visiting the U.S. Federal Communications
            Commission website prior to making any modifications to the default
            templates.
          </p>
          <p className={styles.description}>
            <b>Opt-Out</b>
          </p>
          <p className={styles.description}>
            TCPA regulations require the Customer to honor customer requests to
            opt-out of future telephone, fax, or text messages. The Mechanic
            Advisor Service allows the Customer to honor these requests on an
            individual basis. A patron may also opt-out of text messages at any
            time by replying with the word STOP to any text message sent through
            the Mechanic Advisor Service. The Customer is responsible for all
            liability for any failure to receive consent or failure to opt users
            out of the text message feature.
          </p>
          <p className={styles.description}>
            <b>Identity Disclosure</b>
          </p>
          <p className={styles.description}>
            The Customer will not send any text messages that do not contain
            complete and proper identification. Additionally, the Customer may
            not attempt to spoof sender domains, send spam or other offending
            text message practices including those covered in the Section titled
            “Customer responsibility and passwords; third-party software” of
            this Agreement.
          </p>
          <p className={styles.description}>
            Mechanic Advisor makes no expressed or implied warranty of
            individual message receipt. Standard text message rates apply for
            all text message services. Mechanic Advisor shall not be liable for
            any issues that arise associated with the content that the Customer
            provides or unforeseen liabilities of it being delivered. The
            Customer shall be solely liable to comply with applicable laws and
            regulations within the Customer’s jurisdiction in connection with
            telecommunication (e.g., email and text) messages that the Customer
            sends to theirpatrons.
          </p>
          <p className={styles.description}>
            For more information about the Customer’s responsibilities under the
            TCPA, please visit the{" "}
            <b>U.S. Federal Communications Commission website.</b>
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 15 - Giving us access to other accounts and services
          </div>
          <p className={styles.description}>
            The Service may require the Customer to give Mechanic Advisor access
            to or require the Customer to provide login information and password
            information for accounts or services the Customer has with third
            party providers. When the Customer provides this information to
            Mechanic Advisor or gives Mechanic Advisor access to these third
            party accounts, the Customer agrees that they have read all
            contracts and written agreements governing such access, login
            information and passwords and that the Customer has all the
            necessary contractual and legal rights to give Mechanic Advisor such
            access, login information and passwords. In addition, for certain
            Services, Mechanic Advisor grants the Customer permission to create
            and submit content on the Customer’s behalf to press release news
            outlets, directories, search engines, social media outlets, and
            other destinations.
          </p>
        </div>
        <div className={styles.term}>
          <div className={styles.title}>
            Section 16 - Governing law; Venue; Dispute and waiver of class
            action
          </div>
          <p className={styles.description}>
            i. Governing law. This Agreement will be interpreted, construed, and
            enforced in all respects in accordance with the laws of the State of
            Massachusetts, without reference to its choice of law principles to
            the contrary.
          </p>
          <p className={styles.description}>
            ii. Venue. The Customer will not commence or prosecute any action,
            suit, proceeding or claim arising under or by reason of this
            Agreement other than in the state or federal courts located in
            Boston, Massachusetts. The Customer irrevocably consent to the
            jurisdiction and venue of the courts identified in the preceding
            sentence in connection with any action, suit, proceeding, or claim
            arising under or by reason of this Agreement and hereby waive the
            right to challenge the jurisdiction of such courts on grounds of
            lack of personal jurisdiction or forum nonveniens or to otherwise
            seek a change of venue. This choice of venue is intended to be
            mandatory, and not permissive, in nature. The Customer hereby waives
            any right to a jury trial in any proceeding arising out of or
            related to this Agreement. The Customer agrees that regardless of
            any statute or law to the contrary, any claim or cause of action
            arising out of or related to use of the Service or this Agreement
            must be filed within one (1) year after such claim or cause of
            action arose or be forever barred. The United Nations Convention on
            Contracts for the International Sale of Goods does not apply to this
            Agreement. A printed version of this Agreement and of any related
            notice given in electronic form shall be admissible in judicial or
            administrative proceedings based upon or relating to this Agreement
            to the same extent and subject to the same conditions as other
            business documents and records originally generated and maintained
            in printed form.
          </p>
          <p className={styles.description}>
            <b>Questions.</b>
          </p>
          <p className={styles.description}>
            Any questions regarding this Agreement, or if youwish to obtain
            additional information, please send an e-mail to{" "}
            <b>
              {" "}
              <a href="mailto:support@mechanicadvisor.com">
                support@MechanicAdvisor.com
              </a>
              .
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
